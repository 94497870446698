import React, { Component } from "react";
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';

import AvAIcon from '../assets/ava_icon.png';
import PvPIcon from '../assets/pvp_icon.png';
import RecyclageIcon from '../assets/pepites_icon.png';
import EntraideIcon from '../assets/entraide_icon.png';

function AvAFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
      { filterElement }
      { column.text }
      <img src={AvAIcon} alt="AvA" />
      { sortElement }
    </div>
  )
}

function PvPFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
      { filterElement }
      { column.text }
      <img src={PvPIcon} alt="PvP" />
      { sortElement }
    </div>
  )
}

function RecyclageFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
      { filterElement }
      { column.text }
      <img src={RecyclageIcon} alt="Recyclage" />
      { sortElement }
    </div>
  )
}

function EntraideFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
      { filterElement }
      { column.text }
      <img src={EntraideIcon} alt="Entraide" />
      { sortElement }
    </div>
  )
}

function BaseFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
      { filterElement }
      { column.text }
      { sortElement }
    </div>
  )
}

const columns = [{
  dataField: 'nickname',
  text: 'Pseudo'
}, {
  dataField: 'score',
  text: 'Empitons',
  sort: true,
  headerFormatter: BaseFormatter,
}, {
  dataField: 'scoreAva',
  text: 'AvA',
  sort: true,
  headerFormatter: AvAFormatter,
}, {
  dataField: 'scorePvp',
  text: 'PvP',
  sort: true,
  headerFormatter: PvPFormatter,
}, {
  dataField: 'scorePepites',
  text: 'Recyclage',
  sort: true,
  headerFormatter: RecyclageFormatter,
},, {
  dataField: 'scoreEntraide',
  text: 'Entraide',
  sort: true,
  headerFormatter: EntraideFormatter,
}];

const defaultSorted = [{
  dataField: 'score', 
  order: 'desc' 
}];

export default class Scoreboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      members: []
    };
  }

  componentDidMount() {
    axios.get('https://empirium.cryptomothy.club/api/members')
    .then(res => {
      this.setState({
        members: res.data
      });
    })
    .catch((error) => {
      console.log(error);
    })
  }

  render() {
    return (
      <BootstrapTable keyField='name' data={ this.state.members } columns={ columns } defaultSorted={ defaultSorted } bordered={ true } hover={ true } />
    )
  }
}