import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './custom.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './App.css'
import Scoreboard from './components/scoreboard.component'
import Logo from './assets/logo.png';

function App() {
  return (
    <div className="App">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-auto">
            <h1 className="text-primary mt-5 mb-5">
              <span>
                <img src={ Logo } alt="Empirium" />
              </span>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Scoreboard></Scoreboard>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default App